<template>
  <div>
    <earning-confirm-search ref="search" @onExport="onExport" @search="search" />
    <CenterTabs v-model="tabValue" :tab-options="tabOptions" @tabChange="tabChange"></CenterTabs>
    <div v-if="totalStatisticsArr.length > 0" class="total-list">
      <div v-for="item in totalStatisticsArr" :key="item.orderStateName" class="total-item" @click="filterStatusList(item.orderState)">
        <div>
          {{ item.orderStateName || '' }} <span>({{ item.payOrderNum || 0 }})</span>
        </div>

        <el-popover placement="top-start" title="" width="100" trigger="hover" content="已退/全部" :disabled="!item.orderStateName.includes('部分退款')">
          <el-statistic slot="reference" class="amount" group-separator="," :precision="2" :value="item.amount || 0">
            <template slot="prefix">¥</template>
          </el-statistic>
        </el-popover>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="practicalFinishAmount" label="实际完成金额" header-align="center" width="120" align="center"> </el-table-column>
      <el-table-column prop="paymentOrderRecordNo" label="确认收入编号" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="dueFinishAmount" label="应该完成金额" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="finishTime" label="完成时间" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="appointmentTime" label="预约时间" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="storeName" label="服务门店" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="cityName" label="服务城市" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="brandNames" label="所属品牌" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="orderTypeName" label="订单类型" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="productNames" label="订单产品" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="serviceNo" label="关联服务单" header-align="center" width="140" align="center">
        <template slot-scope="{ row }">
          <el-link :underline="false" type="primary" @click="toServiceDetail(row)">{{ row.serviceNo }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="所属客户" header-align="center" width="120" align="center">
        <template slot-scope="{ row }">
          <el-link :underline="false" type="primary" @click="goCustDetail(row)">{{ row.userName }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="outTradeNo" label="关联订单" header-align="center" width="140" align="center">
        <template slot-scope="{ row }">
          <el-link :underline="false" type="primary" @click="toDetail(row)">{{ row.outTradeNo }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="orderCreateTime" label="下单时间" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="saleUserName" label="销售顾问" header-align="center" width="120" align="center"> </el-table-column>
      <el-table-column prop="saleUserDepartName" label="销售部门" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="channelName" label="订单渠道" header-align="center" width="100" align="center"> </el-table-column>
      <el-table-column prop="receivableAmount" label="关联订单应付金额" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="paymentAmount" label="关联订单已付金额" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="nonPaymentAmount" label="关联订单待付金额" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="refundableAmount" label="关联订单已退金额" header-align="center" width="140" align="center"> </el-table-column>
      <el-table-column prop="refundableAmount" label="服务人员" header-align="center" width="180" align="center">
        <template slot-scope="{ row }">
          <div class="person">
            <div v-for="item in row.finishItemInfoList" :key="item.serviceItemId">
              <span>{{ item.baseServiceTypeName }}</span
              >: <span>{{ item.serviceUserName }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <i-pagination
      :page-size="page.pageSize"
      :current-page="page.pageNumber"
      :page-sizes="[10, 20, 30, 40]"
      :total="page.total"
      @current-change="handlePageChange"
      @size-change="handleSizeChange"
    />

    <export-dialog :show.sync="showExport" page-type="earning" />
  </div>
</template>

<script>
import EarningConfirmSearch from '../earning-confirm-search/index.vue'
import IPagination from '@/components/i-pagination/index.vue'
import ExportDialog from '../export-dialog/index.vue'
import { paymentRecordList } from '@/api/pay'
import CenterTabs from '../tabs/index.vue'
import { findStatisticConfirm, exportList } from '@/api/financeAccountStatistics'
export default {
  components: { EarningConfirmSearch, IPagination, ExportDialog, CenterTabs },
  data() {
    return {
      totalStatisticsArr: [],
      tableData: [],
      searchForm: {
        recordType: 2,
        orderType: '',
        bizType: '',
      },
      page: {
        pageSize: 10,
        pageNumber: 1,
        total: 0,
      },
      showExport: false,
      tabValue: 0,
      tabOptions: [
        {
          title: '完成金额',
          amount: 0,
          number: 0,
        },
        {
          title: '服务单金额',
          amount: 0,
          number: 0,
        },
        {
          title: '加购单金额',
          amount: 0,
          number: 0,
        },
      ],
    }
  },
  methods: {
    toServiceDetail(row) {
      window.open(`${window.origin}/index/service/serviceOrder/serviceParticulars?id=${row.serviceId}`)
    },
    toDetail(row) {
      if ([1, 3].includes(row.orderType)) {
        window.open(`${window.origin}/index/service/receivingOrder/receivingParticulars?id=${row.orderId}`)
      } else {
        window.open(`${window.origin}/index/service/purchaseOrder/purchaseParticulars?id=${row.orderId}`)
      }
    },
    goCustDetail(row) {
      window.open(`${window.origin}/index/chance/clientFollowUp/particulars?id=${row.customerId}`)
    },
    // 重置搜索条件
    reset() {
      this.tabValue = 0
      this.tabOptions = this.$options.data().tabOptions
      this.searchForm = this.$options.data().searchForm
      this.page = this.$options.data().page
      this.$refs.search.onReset()
    },
    search(params) {
      this.searchForm = { ...this.searchForm, ...params }
      this.page = this.$options.data().page
      this.getData()
      this.getStatisticsData()
    },
    getData() {
      const params = {
        data: { ...this.searchForm },
        ...this.page,
      }
      paymentRecordList(params).then((res) => {
        this.tableData = res.content
        this.page.total = parseInt(res.total)
      })
    },
    // 获取统计数据
    getStatisticsData() {
      const data = { ...this.searchForm }
      findStatisticConfirm({ data }).then((res) => {
        if (Object.keys(res).length) this.handleData(res)
      })
    },
    tabChange({ type }) {
      this.searchForm.orderType = type
      this.getData()
    },
    // 处理统计数据
    handleData(data) {
      const { totalAmount, totalNum, serviceTotalAmount, serviceTotalNum, serviceOrderType, additionTotalAmount, additionTotalNum, additionOrderType } = data
      const total = {
        amount: totalAmount,
        number: totalNum,
        type: '',
      }
      const service = {
        amount: serviceTotalAmount,
        number: serviceTotalNum,
        type: serviceOrderType,
      }
      const addition = {
        amount: additionTotalAmount,
        number: additionTotalNum,
        type: additionOrderType,
      }
      const tabOptions = [total, service, addition]
      tabOptions.forEach((v, i) => {
        tabOptions[i] = {
          ...this.tabOptions[i],
          ...v,
        }
      })
      this.tabOptions = tabOptions
    },
    filterStatusList() {},
    // 页码发生变化
    handlePageChange(e) {
      this.page.pageNumber = e
      this.getData()
    },
    // 每页显示的条数发生的变化
    handleSizeChange(e) {
      this.page.pageSize = e
      this.getData()
    },
    onExport(params) {
      this.searchForm = { ...this.searchForm, ...params }
      this.page = this.$options.data().page
      this.exportList()
    },
    exportList() {
      const params = {
        data: { ...this.searchForm },
        ...this.page,
      }
      const tips = this.$message({
        message: '导出下载中...',
        duration: 0,
        type: 'warning',
      })

      exportList(params).then((res) => {
        const fileName = '收入确认对账单.xlsx'
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          tips.close()
          this.$message.success('导出成功！')
          this.$emit('update:show', false)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.total-list {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff;

  .total-item {
    text-align: left;
    cursor: pointer;
    padding: 10px;

    > div:nth-child(1) {
      font-size: 14px;
      font-weight: Bold;

      span {
        color: rgba(54, 63, 71, 0.6);
        font-weight: normal;
      }
    }

    .amount {
      margin: 6px 0;
      font-size: 20px;
    }

    &:hover {
      background-color: rgba(102, 102, 102, 0.08);
      border-radius: 4px;
    }
  }
}
.person {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  flex-wrap: wrap;
}
::v-deep .el-pagination.is-background .el-pager li {
  height: 28px;
}
</style>
