<template>
  <div class="search">
    <div class="search-box">
      <div class="search-item">
        <label class="search-item__label" for="">订单渠道：</label>
        <!-- <el-cascader v-model="searchData.channelIds" size="small" :options="channeOptions" :props="props"></el-cascader> -->
        <el-select-tree
          id="channelSelect"
          ref="tree"
          v-model="searchData.channelIds"
          size="small"
          class="elSelect"
          :data="channeOptions"
          :props="{
            label: 'channelTypeName',
            child: 'children',
            value: 'id',
          }"
          clearable
          style="width: 216px"
          accordion
          lazy
          :load="onLoadTree"
          placeholder="请选择订单渠道"
          @change="channelTypeChange"
        />
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">订单号：</label>
        <el-input v-model.trim="searchData.outTradeNo" style="width: 215px" size="small" clearable placeholder="请输入订单号"></el-input>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">所属客户：</label>
        <el-input v-model.trim="searchData.userName" style="width: 215px" size="small" clearable placeholder="请输入所属客户"></el-input>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">收支类型：</label>
        <el-select v-model="searchData.bizType" size="small" placeholder="请选择收支类型">
          <el-option v-for="item in payRecordBizTypeOption" :key="item.dictionaryValue" :label="item.dictionaryName" :value="item.dictionaryValue"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">订单类型：</label>
        <el-select v-model="searchData.orderType" size="small" placeholder="请选择订单类型">
          <el-option v-for="item in orderTypeOption" :key="item.dictionaryValue" :label="item.dictionaryName" :value="item.dictionaryValue"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">所属品牌：</label>
        <el-select v-model="searchData.brandId" size="small" placeholder="请选择所属品牌">
          <el-option v-for="item in brandOptions" :key="item.productBrandId" :label="item.brandName" :value="item.productBrandId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">下单城市：</label>
        <el-select v-model="searchData.cityId" size="small" placeholder="请选择下单城市">
          <el-option v-for="item in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">支付端口：</label>
        <el-select v-model="searchData.sourceFrom" size="small" placeholder="请选择支付端口">
          <el-option v-for="item in sourceFromOption" :key="item.dictionaryValue" :label="item.dictionaryName" :value="item.dictionaryValue"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">支付方式：</label>
        <el-select v-model="searchData.channelPayModeCode" size="small" placeholder="请选择支付方式">
          <el-option v-for="item in receivePayTypeOption" :key="item.dictionaryValue" :label="item.dictionaryName" :value="item.dictionaryValue"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">收款商户：</label>
        <el-select v-model="searchData.bizMerIds" size="small" placeholder="请选择收款商户" @change="bizMerChange">
          <el-option v-for="item in bizMerOption" :key="item.id" :label="item.bizMerName" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">商户类型：</label>
        <el-select v-model="searchData.dictionaryId" size="small" placeholder="请选择商户类型" @change="bizMerTypeChange">
          <el-option v-for="item in bizMerTypeOption" :key="item.dictionaryId" :label="item.bizMerTypeName" :value="item.dictionaryId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">收款账户：</label>
        <el-select v-model="searchData.bizAccountId" size="small" placeholder="请选择收款账户" @change="bizAccountChange">
          <el-option v-for="item in bizAccountOption" :key="item.id" :label="item.bizAccountName" :value="item.id"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">销售人员：</label>
        <el-input v-model.trim="searchData.saleUserName" style="width: 215px" size="small" clearable placeholder="请输入内容销售人员"></el-input>
      </div>
      <!-- <div class="search-item">
        <label class="search-item__label" for="">销售部门：</label>
        <el-select v-model="searchData.saleUserDepartIds" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div> -->
      <div class="search-item">
        <label class="search-item__label" for="">收支时间：</label>
        <el-date-picker
          v-model="searchData.operationTime"
          size="small"
          style="width: 340px"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">下单时间：</label>
        <el-date-picker
          v-model="searchData.orderCreateTime"
          size="small"
          style="width: 340px"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <div class="search-item" style="margin-left: 20px">
        <el-button size="small" type="primary" @click="onSearch">查询</el-button>
        <el-button size="small" @click="onReset">重置</el-button>
        <el-button size="small" type="primary" @click="onExport">导出</el-button>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { findBizMerList, findBizAccountList, cityfindNotDisableList, productBrandList, getChannelTree, getChannel, findBizMerTypeList } from '@/api/financeAccountStatistics'
import ElSelectTree from 'el-select-tree'
import { getDictionary } from '@/api/receiveAudit'
const paramsOption = {
  pay_record_biz_type: 'payRecordBizTypeOption',
  revenue_order_type: 'orderTypeOption',
  source_from: 'sourceFromOption',
  receivePay_type: 'receivePayTypeOption',
}
export default {
  components: { ElSelectTree },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      brandOptions: [],
      payRecordBizTypeOption: [], // 收支类型
      orderTypeOption: [], // 订单类型
      sourceFromOption: [], // 支付端口
      receivePayTypeOption: [], // 支付方式
      bizMerOption: [], // 收款商户
      bizAccountOption: [], // 收款账户
      cityList: [],
      channeOptions: [],
      bizMerTypeOption: [], // 商户类型
      props: {
        label: 'channelTypeName',
        value: 'channelTypeId',
        checkStrictly: true,
      },
      searchData: {
        channelIds: '',
        outTradeNo: '',
        userName: '',
        bizType: '',
        bizMerType: '',
        dictionaryId: '',
        orderType: '',
        brandId: '',
        cityId: '',
        channelPayModeCode: '',
        sourceFrom: '',
        bizMerId: '',
        bizMerIds: '',
        bizAccount: '',
        bizAccountId: '',
        operationTime: '',
        orderCreateTime: '',
        payType: '',
        saleUserName: '',
      },
    }
  },
  created() {
    this.getDictionary()
    this.findBizMerList()
    this.findBizAccountList()
    this.cityfindNotDisableList()
    this.productBrandList()
    this.getChannelTree()
    this.findBizMerTypeList()
  },
  methods: {
    onReset() {
      this.searchData = this.$options.data().searchData
      this.onSearch()
    },
    onSearch() {
      const data = JSON.parse(JSON.stringify(this.searchData))
      data.orderCreateTimeStart = data.orderCreateTime ? data.orderCreateTime[0] : ''
      data.orderCreateTimeEnd = data.orderCreateTime ? data.orderCreateTime[1] : ''
      data.operationTimeStart = data.operationTime ? data.operationTime[0] : ''
      data.operationTimeEnd = data.operationTime ? data.operationTime[1] : ''
      if (data.channelIds.length) {
        data.channelIds = [data.channelIds]
      } else {
        data.channelIds = []
      }
      this.$emit('search', data)
    },
    channelTypeChange() {},
    onLoadTree(node, resolve) {
      if (Array.isArray(node.data)) {
        return resolve([])
      }
      this.getChannelList(node.data.channelTypeId).then((res) => {
        return resolve([...res, ...node.data.children])
      })
    },
    async getChannelList(channelTypeId) {
      const res = await getChannel({
        data: {
          channelTypeId,
        },
      })
      return res.map((e) => {
        e.channelTypeName = e.channelName
        e.channelTypeId = e.id + '-' + e.channelId
        e.isLeaf = true
        e.children = []
        return e
      })
    },
    // 商户类型列表
    findBizMerTypeList() {
      findBizMerTypeList({}).then((res) => {
        this.bizMerTypeOption = res
      })
    },
    // 订单渠道
    getChannelTree() {
      getChannelTree({}).then((res) => {
        this.channeOptions = res
      })
    },
    // 处理订单渠道数据
    handleGtChannelTree(list) {
      const newArr = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].children.length) {
          this.handleGtChannelTree(list[i].children)
        } else {
          delete list[i].children
        }
        newArr.push(list[i])
      }
      return newArr
    },
    // 所属品牌
    productBrandList() {
      productBrandList({}).then((res) => {
        this.brandOptions = res
      })
    },
    // 下单城市
    cityfindNotDisableList() {
      cityfindNotDisableList({}).then((res) => {
        this.cityList = res
      })
    },
    // 商户类型
    findBizMerList() {
      findBizMerList({}).then((res) => {
        this.bizMerOption = res
      })
    },
    // 收款账户
    findBizAccountList() {
      findBizAccountList({}).then((res) => {
        this.bizAccountOption = res
      })
    },
    // 获取字典数据
    getDictionary() {
      const keys = Object.keys(paramsOption)
      const data = { dictionaryKey: '' }
      keys.forEach((key) => {
        data.dictionaryKey = key
        getDictionary({ data }).then((res) => {
          this[paramsOption[key]] = res
        })
      })
    },
    // 选择商户类型获取对应的payType
    bizMerTypeChange(e) {
      const obj = this.bizMerTypeOption.find((v) => v.dictionaryId === e)
      this.searchData.payType = obj.payType
      this.searchData.bizMerType = obj.bizMerType
    },
    // 选择商户类型获取对应的payType
    bizMerChange(e) {
      const obj = this.bizMerOption.find((v) => v.id === e)
      this.searchData.payType = obj.payType
      this.searchData.bizMerId = obj.bizMerId
    },
    // 选择收款账户获取对应的payType
    bizAccountChange(e) {
      const obj = this.bizAccountOption.find((v) => v.id === e)
      this.searchData.payType = obj.payType
      this.searchData.bizAccount = obj.bizAccount
    },
    // 导出
    onExport() {
      const data = JSON.parse(JSON.stringify(this.searchData))
      data.orderCreateTimeStart = data.orderCreateTime ? data.orderCreateTime[0] : ''
      data.orderCreateTimeEnd = data.orderCreateTime ? data.orderCreateTime[1] : ''
      data.operationTimeStart = data.operationTime ? data.operationTime[0] : ''
      data.operationTimeEnd = data.operationTime ? data.operationTime[1] : ''
      if (data.channelIds.length) {
        data.channelIds = [data.channelIds]
      } else {
        data.channelIds = []
      }
      this.$emit('onExport',data)
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-top: 20px;

  &-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden;
  }

  &-item {
    margin-right: 10px;
    margin-bottom: 10px;
    white-space: nowrap;

    &__label {
      width: 90px;
      display: inline-block;
      font-weight: 500;
      text-align: right;
    }
  }
}
</style>
