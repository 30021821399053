import request from '@/utils/request';

// 查询商户类型 - 包含了线上商户，线下商户 （用于财务对账单的查询条件下拉框）
export function findBizMerList(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/findBizMerList',
    method: 'post',
    data
  });
}
// 查询业务账户 - 包含了线上账户，线下账户 （用于财务对账单的查询条件下拉框）
export function findBizAccountList(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/findBizAccountList',
    method: 'post',
    data
  });
}
// 城市
export function cityfindNotDisableList(data) {
  return request({
    url: 'misc-web-api/api/photo/city/findNotDisableList',
    method: 'post',
    data
  });
}
// 品牌
export function productBrandList(data) {
  return request({
    url: 'product-web-api/api/productBrand/list',
    method: 'post',
    data
  });
}
// 门店列表
export function storeList(data) {
  return request({
    url: 'supplier-web-api/admin/store/getList',
    method: 'post',
    data
  });
}
// 获取渠道类型树(已启用的)
export function getChannelTree(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTree',
    method: 'post',
    data
  });
}
// 获取渠道类型树(已启用的)
export function getChannel(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelList',
    method: 'post',
    data
  });
}
// 导出
export function exportList(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/exportList',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
// 预收款统计
export function findStatisticRevenue(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/findStatisticRevenue',
    method: 'post',
    data
  });
}
// 收入确认款统计
export function findStatisticConfirm(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/findStatisticConfirm',
    method: 'post',
    data
  });
}
// 查询商户类型列表
export function findBizMerTypeList(data) {
  return request({
    url: 'pay-web-api/admin/payment/order/record/findBizMerTypeList',
    method: 'post',
    data
  });
}