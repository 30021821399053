<template>
  <div class="financeAccountStatistics">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="预收款对账单" name="advances">
        <advances-received ref="advances"/>
      </el-tab-pane>
      <el-tab-pane label="收入确认对账单" name="earning">
        <earning-confirm ref="earning" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { AdvancesReceived, EarningConfirm } from './components';
export default {
  components: { AdvancesReceived, EarningConfirm },
  data() {
    return {
      activeName: 'advances'
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      this.$refs[this.activeName].getData();
      this.$refs[this.activeName].getStatisticsData();
    },
    handleClick(e) {
      this.$refs[this.activeName].reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.financeAccountStatistics {
  background: #fff;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;

  ::v-deep .el-tabs__content{
    overflow: visible !important;
  }
}
</style>
