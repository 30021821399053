<template>
    <div class="tabs">
        <div v-for="(item, i) in options" :key="i" class="item" :class="{ active: tabValue == i }" @click="tabsChange(i)">
            <div>
                <span class="title">{{ item.title }}</span>
                <span class="total"> ({{ item.number }}笔)</span>
            </div>
            <div class="amount">￥{{ item.amount }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Number],
        tabOptions: Array
    },
    data() {
        return {
            tabValue: 0,
            options: []
        }
    },
    watch: {
        value: {
            handler(val, oldVal) {
                this.tabValue = val
            },
            immediate: true,
        },
        tabOptions: {
            handler(val, oldVal) {
                this.$set(this, 'options', val)
            },
            deep: true
        }
    },
    methods: {
        tabsChange(e) {
            this.tabValue = e
            this.$emit('input', e)
            this.$emit('tabChange', this.options[e])
        },

    }

}
</script>

<style lang="scss" scoped>
.tabs {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    .item {
        flex: 1;
        height: 88px;
        background: #FAFAFA;
        border-radius: 4px 4px 4px 4px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        .title {
            font-size: 14px;
            font-weight: 500;
            color: #363F47;
        }

        .total {
            font-size: 14px;
            font-weight: 500;
            color: rgba(54, 63, 71, 0.6);
        }

        .amount {
            font-size: 20px;
            font-weight: 400;
            color: #363F47;
        }

        &:hover {
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);
            border-radius: 4px 4px 4px 4px;
        }
    }

    .active {
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);
        border-radius: 4px 4px 4px 4px;

        .title {
            font-weight: bold;
        }

        .amount {
            font-weight: bold;
        }

        &::before {
            content: '';
            display: block;
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #2861E2;
            border-radius: 0px 0px 0px 0px;
        }
    }

}
</style>