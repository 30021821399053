<template>
  <el-dialog title="按条件导出" :visible="show" width="600px" @close="cancel">
    <div class="exportDialog">
      <el-form class="export-form">
        <el-form-item label="下单时间：">
          <el-date-picker
            v-model="createTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <div class="tips">注意：时间快捷选项中的一个月为30天三个月为90天</div>
        </el-form-item>

        <el-form-item v-if="pageType !== 'advances'" label="完成时间：" >
          <el-date-picker
            v-model="finishTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <div class="tips">注意：时间快捷选项中的一个月为30天三个月为90天</div>
        </el-form-item>
        <el-form-item v-else label="支付退款时间：">
          <el-date-picker
            v-model="operationTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <div class="tips">注意：时间快捷选项中的一个月为30天三个月为90天</div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div style="text-align: right">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="exportData">导出</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { 
  exportList
} from '@/api/financeAccountStatistics'
export default {
  name: '',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    exportFunc: {
      type: Function,
      default: () => {},
    },
    pageType:{
      type: String,
      default: 'advances'
    }
  },
  data() {
    var vm = this
    return {
      createTime: '',
      finishTime: '',
      operationTime: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            vm.firstClickTime = minDate
          }
        },
        disabledDate(time) {
          const timestamp = new Date(new Date().toLocaleDateString()).getTime()+ 24 * 60 * 60 * 1000 - 1
          if (vm.firstClickTime === '') {
            return time.getTime() > timestamp
          } else {
            return time.getTime() > timestamp || time.getTime() - 15552000000 > vm.firstClickTime.getTime() || time.getTime() + 15552000000 < vm.firstClickTime.getTime()
          }
        },
      },
      isFirstClick: false,
      firstClickTime: '',
      status: '',
      orderStatus: '',
    }
  },

  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
  },

  methods: {
    exportData() {
      console.log(this.createTime,'----');
      const data = {
        data: {
          recordType:this.pageType === 'advances' ? 1 : 2,
          orderStatus: this.orderStatus,
          orderCreateTimeStart: this.createTime[0] || '',
          orderCreateTimeEnd: this.createTime[1] || '',
          finishTimeStart: this.finishTime[0] || '',
          finishTimeEnd: this.finishTime[1] || '',
          operationTimeStart: this.operationTime[0] || '',
          operationTimeEnd: this.operationTime[1] || '',
          export: 1,
        },
      }

      const tips = this.$message({
        message: '导出下载中...',
        duration: 0,
        type: 'warning',
      })

      exportList(data).then((res) => {
        const fileName = this.pageType === 'earning' ? "收入确认对账单.xlsx" : "预收款对账单.xlsx"
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          tips.close()
          this.$message.success('导出成功！')
          this.$emit('update:show', false)
        }
      })
    },

    cancel() {
      this.createTime = ''
      this.finishTime = ''
      this.operationTime = ''
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.exportDialog {
  .tips {
    color: #9a9d9e;
    font-size: 14px;
  }
}
</style>
