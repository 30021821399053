<template>
  <div class="search">
    <div class="search-box">
      <div class="search-item">
        <label class="search-item__label" for="">关联订单：</label>
        <el-input v-model.trim="searchData.outTradeNo" style="width: 215px" size="small" clearable placeholder="请输入关联订单"></el-input>
      </div>

      <div class="search-item">
        <label class="search-item__label" for="">订单类型：</label>
        <el-select v-model="searchData.orderType" size="small" placeholder="请选择订单类型">
          <el-option v-for="item in orderTypeOptions" :key="item.dictionaryValue" :label="item.dictionaryName" :value="item.dictionaryValue"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">所属客户：</label>
        <el-input v-model.trim="searchData.userName" style="width: 215px" size="small" clearable placeholder="请输入所属客户"></el-input>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">所属品牌：</label>
        <el-select v-model="searchData.brandId" size="small" placeholder="请选择所属品牌">
          <el-option v-for="item in brandOptions" :key="item.productBrandId" :label="item.brandName" :value="item.productBrandId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">服务城市：</label>
        <el-select v-model="searchData.cityId" size="small" placeholder="请选择服务城市">
          <el-option v-for="item in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">服务门店：</label>
        <el-select v-model="searchData.storeId" size="small" placeholder="请选择服务门店">
          <el-option v-for="item in storesOptions" :key="item.storeId" :label="item.storeName" :value="item.storeId"> </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">订单渠道：</label>
        <el-select-tree
          id="channelSelect"
          ref="tree"
          v-model="searchData.channelIds"
          size="small"
          class="elSelect"
          :data="channeOptions"
          :props="{
            label: 'channelTypeName',
            child: 'children',
            value: 'id',
          }"
          clearable
          style="width: 216px"
          accordion
          lazy
          :load="onLoadTree"
          placeholder="请选择订单渠道"
          @change="channelTypeChange"
        />
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">服务单号：</label>
        <el-input v-model.trim="searchData.serviceNo" style="width: 215px" size="small" clearable placeholder="请输入服务单号"></el-input>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">销售人员：</label>
        <el-input v-model.trim="searchData.saleUserName" style="width: 215px" size="small" clearable placeholder="请输入销售人员"></el-input>
      </div>
      <!-- <div class="search-item">
        <label class="search-item__label" for="">销售部门：</label>
        <el-select v-model="searchData.saleUserDepart" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div> -->
      <div class="search-item">
        <label class="search-item__label" for="">完成时间：</label>
        <el-date-picker
          v-model="searchData.finishTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          style="width: 340px"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">下单时间：</label>
        <el-date-picker
          v-model="searchData.orderCreateTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          style="width: 340px"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <label class="search-item__label" for="">预约时间：</label>
        <el-date-picker
          v-model="searchData.appointmentTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="small"
          style="width: 340px"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>

      <div class="search-item" style="margin-left: 20px">
        <el-button size="small" type="primary" @click="onSearch">查询</el-button>
        <el-button size="small" @click="onReset">重置</el-button>
        <el-button size="small" type="primary" @click="onExport">导出</el-button>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { storeList, getChannelTree, productBrandList, cityfindNotDisableList, getChannel } from '@/api/financeAccountStatistics'
import { getDictionary } from '@/api/receiveAudit'
import ElSelectTree from 'el-select-tree'
export default {
  components: { ElSelectTree },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      orderTypeOptions: [
        {
          label: '收款订单',
          value: 1,
        },
        {
          label: '加购订单',
          value: 2,
        },
        {
          label: '服务订单',
          value: 3,
        },
      ],
      brandOptions: [],
      cityList: [],
      options: [],
      storesOptions: [],
      channeOptions: [],
      props: {
        label: 'channelTypeName',
        value: 'channelTypeId',
        checkStrictly: true,
      },
      searchData: {
        outTradeNo: '',
        orderType: '',
        userName: '',
        brandId: '',
        cityId: '',
        storeId: '',
        channelIds: '',
        serviceNo: '',
        saleUserId: '',
        saleUserDepart: '',
        finishTime: '',
        orderCreateTime: '',
        appointmentTime: '',
        saleUserName: '',
      },
    }
  },
  created() {
    this.storeList()
    this.getProductBrand()
    this.getChannelTree()
    this.getCityList()
    this.getDictionary()
  },
  methods: {
    // 获取字典数据
    getDictionary() {
      const data = { dictionaryKey: 'confirm_order_type' }
      getDictionary({ data }).then((res) => {
        this.orderTypeOptions = res
      })
    },
    // 订单渠道
    channelTypeChange() {},
    onLoadTree(node, resolve) {
      if (Array.isArray(node.data)) {
        return resolve([])
      }
      this.getChannelList(node.data.channelTypeId).then((res) => {
        return resolve([...res, ...node.data.children])
      })
    },
    async getChannelList(channelTypeId) {
      const res = await getChannel({
        data: {
          channelTypeId,
        },
      })
      return res.map((e) => {
        e.channelTypeName = e.channelName
        e.channelTypeId = e.id + '-' + e.channelId
        e.isLeaf = true
        e.children = []
        return e
      })
    },
    // 订单渠道数据
    getChannelTree() {
      getChannelTree({}).then((res) => {
        this.channeOptions = res
      })
    },
    // 处理订单渠道数据
    handleGtChannelTree(list) {
      const newArr = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].children.length) {
          this.handleGtChannelTree(list[i].children)
        } else {
          delete list[i].children
        }
        newArr.push(list[i])
      }
      return newArr;
    },
    // 服务门店
    storeList() {
      storeList({ data: {}, pageNumber: 1, pageSize: 1000 }).then((res) => {
        this.storesOptions = res.content
      })
    },
    onReset() {
      this.searchData = this.$options.data().searchData
      this.onSearch()
    },
    onSearch() {
      const data = JSON.parse(JSON.stringify(this.searchData))
      data.finishTimeStart = data.finishTime ? data.finishTime[0] : ''
      data.finishTimeEnd = data.finishTime ? data.finishTime[1] : ''
      data.orderCreateTimeStart = data.orderCreateTime ? data.orderCreateTime[0] : ''
      data.orderCreateTimeEnd = data.orderCreateTime ? data.orderCreateTime[1] : ''
      data.appointmentTimeStart = data.appointmentTime ? data.appointmentTime[0] : ''
      data.appointmentTimeEnd = data.appointmentTime ? data.appointmentTime[1] : ''
      if (data.channelIds.length) {
        data.channelIds = [data.channelIds]
      } else {
        data.channelIds = []
      }
      this.$emit('search', data)
    },
    // 所属品牌
    getProductBrand() {
      productBrandList({}).then((res) => {
        this.brandOptions = res
      })
    },
    // 服务城市
    getCityList() {
      cityfindNotDisableList({}).then((res) => {
        this.cityList = res
      })
    },
    onExport() {
      const data = JSON.parse(JSON.stringify(this.searchData))
      data.finishTimeStart = data.finishTime ? data.finishTime[0] : ''
      data.finishTimeEnd = data.finishTime ? data.finishTime[1] : ''
      data.orderCreateTimeStart = data.orderCreateTime ? data.orderCreateTime[0] : ''
      data.orderCreateTimeEnd = data.orderCreateTime ? data.orderCreateTime[1] : ''
      data.appointmentTimeStart = data.appointmentTime ? data.appointmentTime[0] : ''
      data.appointmentTimeEnd = data.appointmentTime ? data.appointmentTime[1] : ''
      if (data.channelIds.length) {
        data.channelIds = [data.channelIds]
      } else {
        data.channelIds = []
      }
      this.$emit('onExport', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-top: 20px;

  &-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    overflow: hidden;
  }

  &-item {
    margin-right: 10px;
    margin-bottom: 10px;
    white-space: nowrap;

    &__label {
      width: 90px;
      display: inline-block;
      font-weight: 500;
      text-align: right;
    }
  }
}
</style>
